import { FC, useEffect, useState } from 'react'
import {
	Box,
	ButtonDropdown,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween,
	Container
} from '@cloudscape-design/components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useSingleProjectResource from '../../../../../hooks/query/useSingleProjectResource'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { ProjectResource, ProjectResourceApprovalRequest } from '../../../../../types/projectResource'
import HeaderWithStatusBadges from '../../../../../components/Common/HeaderWithStatusBadges'
import RenderSslCertFields from './RenderSslCertFields'
import { useOktaAuth } from '@okta/okta-react'
import RequiredPermissions from '../../../../../helper/RequiredPermissions'
import { ApprovalForm } from './ApprovalForm'
import { RejectionForm } from './RejectionForm'
import CommentsOverview from '../../../../../components/Project/CommentsOverview'
import ApproverOverview from '../../../../../components/ProjectResource/ApprovalOverview'
import useAcceptProjectResource from '../../../../../hooks/mutate/useAcceptProjectResource'
import CONSTANTS from '../../../../../constants'
import useUserPermissions from '../../../../../hooks/query/useUserPermissions'

const getKeyValueSslCertResource = (resource: ProjectResource) => {
	let resourceArray = [
		{
			label: 'Certificate Type',
			value: resource.details.certType
		},
		{
			label: 'Account Distribution List',
			value: resource.details.accountDistributionList
		},
		{
			label: resource.details.projectName ? 'Project Name' : 'Project ID',
			value: <Link to={`/projects/${resource.projectID}`}>{resource.details.projectName || resource.projectID}</Link>
		},
		{
			label: 'Domain Name',
			value: resource.details.domainName
		},
		{
			label: 'Hosted Zone Apex Name',
			value: resource.details.hostedZoneName
		},
		{
			label: 'Requestor Email',
			value: resource.requestorID
		}
	]

	if (resource.approval?.approverID !== undefined && resource.status === 'APPROVED') {
		resourceArray.push({
			label: 'AWS Tribe Approver Email',
			value: resource.approval?.approverID
		})
	}

	if (resource.approvalStatus[0].approverID !== undefined) {
		resourceArray.push({
			label: 'ISDP Approver Email',
			value: resource.approvalStatus[0].approverID
		})
	}
	return resourceArray
}

const SslCertDetails: FC = () => {
	const { resourceId } = useParams() as { resourceId: string }

	const { authState } = useOktaAuth()
	const CURRENT_USER_ID = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const { data, isLoading, refetch } = useSingleProjectResource(resourceId)
	const { mutate: acceptSslCert, isLoading: isAcceptSslCertLoading } = useAcceptProjectResource(resourceId)
	const {data: userPermissions} = useUserPermissions(CURRENT_USER_ID)

	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)

	const hasSpecificPermissions = (
		permissions: string[],
		requiredPermissions: string[]
	): boolean => {
		return requiredPermissions.some((requiredPerm) =>
			permissions.includes(requiredPerm)
		)
	}

	const specificPermissions = ['projects:*:*', 'projects:*:approve', '*:*:*']
	const hasRequiredPermissions = userPermissions && hasSpecificPermissions(userPermissions.permissions, specificPermissions)

	// Permissions per role:
	// Requestor: 'projects:*:read', 'projects:*:create'
	// ISDP Approver: 'projects:*:read', 'projectResources:ISDP:approve'
	// AWS Tribe Approver: 'projects:*:read', 'projects:*:approve'

	if (isLoading) {
		return <LoadingSpinner />
	}

	if (data === undefined) {
		navigate(-1)
	}

	if (data?.statusCode !== 200) {
		let message = data?.body as { message: string }
		return (
			<>
				<h1>Error {data?.statusCode}</h1>
				<p>{message.message}</p>
			</>
		)
	}

	const handleAwsTribeApprove = () => {
		const approvalRequest: ProjectResourceApprovalRequest = {
			projectResourceID: resourceId,
			approverID: CURRENT_USER_ID,
			timestamp: new Date().toISOString(),
			details: {},
			action: 'APPROVE',
			resourceType: 'SSL_CERT'
		}

		acceptSslCert(approvalRequest, {
			onSuccess: ({data}) => {
				refetch()
			}
		})

		console.log(approvalRequest)
	}

	let sslCertDetails = data?.body as ProjectResource
	let parsedDetails = getKeyValueSslCertResource(sslCertDetails)

	let dropdownItems: {text: string, id: string}[] = []
	if (sslCertDetails.approvalStatus !== undefined) {
		dropdownItems = [{ text: 'Approve', id: 'approve' },{ text: 'Reject', id: 'reject' }]
		for (const approval of sslCertDetails.approvalStatus) {
			if (approval.status !== 1) {
				dropdownItems = [{ text: 'Reject', id: 'reject' }]
			}
		}
	}

	return (
		<ContentLayout
			header={
				<HeaderWithStatusBadges
					headerName={`${sslCertDetails.details.domainName}.${sslCertDetails.details.hostedZoneName}`}
					status={sslCertDetails.status}
				/>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderSslCertFields sslCert={parsedDetails} />

					{!hasRequiredPermissions && CURRENT_USER_ID === sslCertDetails.requestorID && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PROJECT_REQUESTOR}>
							<Container>
								<ApproverOverview approvalStatus={sslCertDetails.approvalStatus} projectResourceID={resourceId} isDisabled={true} />
							</Container>
						</RequiredPermissions>
					)}
					
					{(sslCertDetails.status === 'PENDING_APPROVAL' && CURRENT_USER_ID !== sslCertDetails.requestorID) && (
						<RequiredPermissions requiredPermissions={[...CONSTANTS.PROJECT_READER, 'projectResources:ISDP:approve']}>
							<Container>
								<ApproverOverview approvalStatus={sslCertDetails.approvalStatus} projectResourceID={resourceId} />
							</Container>
						</RequiredPermissions>
					)}
					
					{showApprovalForm && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PROJECT_APPROVER}>
							<ApprovalForm showForm={setShowApprovalForm} isWildcard={sslCertDetails.details.certType === 'wildcard'}/>
						</RequiredPermissions>
					)}
					{showRejectionForm && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PROJECT_APPROVER}>
							<RejectionForm showForm={setShowRejectionForm}/>
						</RequiredPermissions>
					)}

					{(sslCertDetails.status === 'PENDING_APPROVAL' &&
						CURRENT_USER_ID !== sslCertDetails.requestorID) && (
							<RequiredPermissions requiredPermissions={['projects:*:read', 'projects:*:approve']}>
								<Container>
									<ApproverOverview approvalStatus={sslCertDetails.approvalStatus} projectResourceID={resourceId} />
								</Container>
								<Box float="left" margin={{top: 'l'}}>
									<ButtonDropdown
										items={dropdownItems}
											onItemClick={({detail}) => {
												if (detail.id === 'approve') {
													handleAwsTribeApprove()
												}

												if (detail.id === 'reject') {
													setShowApprovalForm(false)
													setShowRejectionForm(true)
												}
											}}
											loading={isAcceptSslCertLoading}>
										For AWS Tribe Action
									</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						)}
				</SpaceBetween>
				<SpaceBetween direction="vertical" size="l">
					<Container header={
						<Header variant="h3">Comment History</Header>
					}>
						<CommentsOverview comments={sslCertDetails.comments || []}/>
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

export default SslCertDetails
