import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { ProjectResource } from "../../types/projectResource";

const useAllProjectResources = () => {
  return useQuery({
    queryKey: ['projectResources'],
    queryFn: () => api.projects.resources.getAllProjectResources(),
    select(data) {
      return data.body as Array<ProjectResource>
    }
  })
}

export default useAllProjectResources