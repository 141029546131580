import {
	Alert,
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	FormField,
	Header,
	Icon,
	Popover,
	RadioGroup,
	SpaceBetween
} from '@cloudscape-design/components'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormMultiselect from '../../../components/ControlledFormInputs/Multiselect'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'
import { useEditMicroserviceFormStore } from '../../../stores/microservices/editMicroserviceFormStore'
import useSingleMicroservice from '../../../hooks/query/useSingleMicroservice'
import useEditMicroservice from '../../../hooks/mutate/useEditMicroservice'
import { MicroserviceEditRequest } from '../../../types/microservices'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'
import CONSTANTS from '../../../constants'

const ENVIRONMENT_LIST = [
	{ label: 'dv', value: 'dev' },
	{ label: 'st', value: 'staging' },
	{ label: 'pd', value: 'prod' }
]

const microserviceKeys = [
	'Application Name',
	'Code Template',
	'Microservice Name',
	'Application URL',
	'Microservice Endpoint',
	'Port Number',
	'WBS Code',
	'Cost Center'
]

const APPLICATION_LIST = [
	{ label: 'application1', value: 'application1' },
	{ label: 'application2', value: 'application2' },
	{ label: 'application3', value: 'application3' }
]

const CODE_TEMPLATE_LIST = [
	{ label: 'NodeJS', value: 'NodeJS' },
	{ label: 'NodeJS + CXS', value: 'NodeJS+CXS' }
]

const MICROSERVICE_ENDPOINT_LIST = [
	{ label: 'Internal', value: 'Internal' },
	{ label: 'External', value: 'External' },
	{ label: 'Internal + External', value: 'InternalAndExternal' }
]

const EditMicroserviceForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { id } = useParams() as { id: string }

	const {
		data: originalMicroserviceRequest,
		isLoading: isLoadingSingleMicroservice
	} = useSingleMicroservice(id)

	const navigate = useNavigate()
	const [wbsCode, setWbsCode] = useState<{
		wbsCode: string
		wbsCodeId: number | null
	}>({
		wbsCode: '',
		wbsCodeId: null
	})

	const [costCenter, setCostCenter] = useState<{
		costCenter: string
		costCenterId: number | null
	}>({
		costCenter: '',
		costCenterId: null
	})

	console.log('You are on the right page')
	useEffect(() => {
		if (
			typeof originalMicroserviceRequest === 'object' &&
			originalMicroserviceRequest !== null
		) {
			const prefetchedObject = {
				...originalMicroserviceRequest.details,
				commentText: ''
			}
			setOriginalRequestFormValues({
				formValues: prefetchedObject,
				isFormValid: true
			})
			reset(prefetchedObject)
		}
	}, [originalMicroserviceRequest])

	const {
		formValues,
		actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
	} = useEditMicroserviceFormStore()

	const {
		mutate: editMicroservice,
		isLoading: isLoadingEditMicroservice,
		isError: isErrorInEditReqeust
	} = useEditMicroservice(id)

	const {
		control,
		reset,
		trigger,
		formState: { isValid, dirtyFields }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { ...formValues.details, commentText: '' }
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const getOriginalObjVersion = () => {
		let deltaObject: any = {}
		const updatedFields: string[] = Object.keys(dirtyFields)
		for (let fieldName of updatedFields) {
			if (fieldName !== 'commentText')
				deltaObject[fieldName] =
					originalMicroserviceRequest?.details[fieldName as keyof object]
		}
		if (Object.keys(deltaObject).length !== 0) {
			deltaObject['lastUpdatedTime'] =
				originalMicroserviceRequest?.lastUpdatedTime
		}
		return deltaObject
	}

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let formFieldValues = JSON.parse(JSON.stringify(formValues))
		let microserviceEditRequest: MicroserviceEditRequest = {
			details: formFieldValues,
			microserviceID: originalMicroserviceRequest?.microserviceID || '',
			requestorID: originalMicroserviceRequest?.requestorID || '',
			creationTime: originalMicroserviceRequest?.creationTime || '',
			action: 'UPDATE',
			version: getOriginalObjVersion(),
			commenterID: thisUserEmail,
			comment: formValues.commentText,
			status: 'PENDING_APPROVAL'
		}
		delete microserviceEditRequest.details.commentText

		editMicroservice(microserviceEditRequest, {
			onSuccess: () => navigate('/microservices'),
			onError: (e) => {}
		})
	}

	if (!originalMicroserviceRequest || isLoadingSingleMicroservice)
		return <LoadingSpinner />

	return (
		<>
			<Form
				actions={
					<SpaceBetween direction="horizontal" size="l">
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoadingEditMicroservice}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</SpaceBetween>
				}
				header={
					<Header variant="h1" description="">
						<SpaceBetween direction="horizontal" size="l">
							<Button
								variant="icon"
								iconName="arrow-left"
								onClick={() => navigate(-1)}
								key="icon"
							/>
							<div key="text">
								{originalMicroserviceRequest?.details.MicroserviceName}
							</div>
						</SpaceBetween>
					</Header>
				}
			>
				<SpaceBetween direction="vertical" size="l">
					<Container>
						<SpaceBetween direction="vertical" size="l">
							<FormSelect
								label="Application Name"
								name="applicationName"
								control={control}
								rules={{
									required: 'Please Select Option'
								}}
								setFormValues={setFormValues}
								options={APPLICATION_LIST}
								placeholder="Select Application"
							/>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div style={{ width: '650px' }}>
									<FormSelect
										name="codeTemplate"
										label="Code Template"
										control={control}
										placeholder="Select Code Template"
										setFormValues={setFormValues}
										// statusType={
										// 	isGcpProjectsLoading ? 'loading' : 'finished'
										// }
										options={CODE_TEMPLATE_LIST}
										rules={{
											required: 'Please select a Code Template'
										}}
									/>
								</div>
								<div style={{ marginLeft: '10px', marginTop: '25px' }}>
									<Popover
										fixedWidth
										header="Platform GCP Project ID tip"
										position="right"
										triggerType="custom"
										content={
											<p>
												Please see guide on how to enter your GCP Project ID:
												<a
													href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Plus"
													style={{
														marginLeft: '10px',
														marginTop: '25px'
													}}
												>
													LINK
												</a>
											</p>
										}
									>
										<Icon name="status-info" />
									</Popover>
								</div>
							</div>
							<FormInput
								name="name"
								label="Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Display Name of Microservice"
								description="Max 20 characters"
								rules={{
									required: 'Please enter Microservice name',
									minLength: {
										value: 2,
										message: 'Minumum length of 2 characters is required'
									},
									pattern: {
										value: /^[a-z0-9-]+$/,
										message:
											'Microservice name should be lowercase only. Hyphens are permitted.'
									},
									// validate: (value) => {
									// 	if (microservices?.some((ms) => ms.status === 'APPROVED' && ms.microserviceName === value)) {
									// 		return 'The microservice with the same name already exists';
									// 	}
									// 	return true;
									// }
								}}
							/>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div style={{ width: '650px' }}>
									<FormInput
										name="applicationUrlPath"
										label="Application URL Path"
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Enter Application URL Path"
										description="The URL/path of application"
										rules={{
											required: 'Please enter application URL',
											minLength: {
												value: 2,
												message: 'Minumum length of 2 characters is required'
											}
										}}
									/>
								</div>
								<div style={{ marginLeft: '10px', marginTop: '35px' }}>
									<Popover
										fixedWidth
										header="Platform GCP Project ID tip"
										position="right"
										triggerType="custom"
										content={
											<p>
												Please see guide on how to enter your GCP Project ID:
												<a
													href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Plus"
													style={{
														marginLeft: '10px',
														marginTop: '25px'
													}}
												>
													LINK
												</a>
											</p>
										}
									>
										<Icon name="status-info" />
									</Popover>
								</div>
							</div>
							<FormInput
								name="portNumber"
								label="Port Number"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Port Number"
								rules={{
									required: 'Please enter port number'
								}}
							/>
							<FormSelect
								label="Endpoint"
								name="endpoint"
								control={control}
								rules={{
									required: 'Please Select Option'
								}}
								setFormValues={setFormValues}
								options={MICROSERVICE_ENDPOINT_LIST}
								placeholder="Select Microservice Endpoint"
							/>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									WBS Code
								</label>
								<RadioGroup
									onChange={({ detail }) =>
										setWbsCode({
											wbsCode: detail.value,
											wbsCodeId: detail.value === 'useExisting' ? 0 : 1
										})
									}
									value={wbsCode.wbsCode}
									items={[
										{ value: 'useExisting', label: 'Use Existing' },
										{ value: 'addNew', label: 'Add New' }
									]}
								/>
								{wbsCode.wbsCodeId === 1 && (
									<FormInput
										name="wbsCode"
										label=""
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Add new WBS Code"
										rules={{
											required: 'Please enter system alias',
											minLength: {
												value: 5,
												message: 'Minumum length of 5 characters'
											},
											pattern: {
												value: /^[a-z0-9]+$/,
												message:
													'Platform name should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
								)}
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Cost Center
								</label>
								<RadioGroup
									onChange={({ detail }) =>
										setCostCenter({
											costCenter: detail.value,
											costCenterId: detail.value === 'useExisting' ? 0 : 1
										})
									}
									value={costCenter.costCenter}
									items={[
										{ value: 'useExisting', label: 'Use Existing' },
										{ value: 'addNew', label: 'Add New' }
									]}
								/>
								{costCenter.costCenterId === 1 && (
									<FormInput
										name="costCenter"
										label=""
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Add new Cost Center"
										rules={{
											required: 'Please enter system alias',
											minLength: {
												value: 5,
												message: 'Minumum length of 5 characters'
											},
											pattern: {
												value: /^[a-z0-9]+$/,
												message:
													'Platform name should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
								)}
							</div>

							<FormRequiredTags
								name="microserviceDetails"
								label="Microservice Details"
								control={control}
								tagKeys={microserviceKeys}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the required value`,
									pattern: {
										value: /^(?!\s)(.*\S)?(?<!\s)$/,
										message: 'Leading and trailing whitespaces are not allowed.'
									}
								}}
							/>
							<FormField label="" description="">
								<ColumnLayout columns={3} variant="default">
									<FormInput
										name="budget"
										label="Platform Budget Allocated"
										description="In US Dollar (USD) currency"
										type="text"
										rules={{
											required: 'Please enter Platform Budget allocated',
											pattern: {
												value: /^\d+$/,
												message:
													'Please enter valid Platform Budget allocated without spaces'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder="0"
									/>
									<FormDatePicker
										name="crfsDate"
										label="CRFS Date"
										description="Customer Ready for Service Date"
										rules={{
											required: 'Please Enter CRFS Date'
										}}
										control={control}
										setFormValues={setFormValues}
									/>
								</ColumnLayout>
							</FormField>
						</SpaceBetween>
					</Container>
					<Container
						header={<Header variant="h3">Comment History</Header>}
						footer={
							<SpaceBetween direction="vertical" size="l">
								<FormInputArea
									name="commentText"
									label=""
									description="Add comments here"
									control={control}
									rules={{
										required: 'Please enter your comments here',
										minLength: {
											value: 2,
											message: 'Minimum comment length should be 2'
										},
										maxLength: {
											value: 50,
											message: 'Maximum comment length should be 50'
										}
									}}
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						}
					></Container>
					{isErrorInEditReqeust && (
						<Container>
							<Box variant="p">
								<Alert
									statusIconAriaLabel="Error"
									type="error"
									header="Error Submitting Request, Please try again."
								/>
							</Box>
						</Container>
					)}
				</SpaceBetween>
			</Form>
		</>
	)
}

export default EditMicroserviceForm
