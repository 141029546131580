import { FC, useEffect } from "react";
import { ProjectResource } from "../../../../types/projectResource";
import { Link, StatusIndicator } from "@cloudscape-design/components";
import { Table } from "@aws-northstar/ui";
import useAllProjectResources from "../../../../hooks/query/useAllProjectResources";
import { useNavigate } from "react-router-dom";

const SslCertificatesOverview: FC = () => {

  const { data: sslCerts, isLoading } = useAllProjectResources()

  const navigate = useNavigate()

  const columnDefinitions = [
    {
      id: 'sslCertName',
      width: 100,
      header: 'SSL Certificate Name',
      cell: (data: ProjectResource) => (
        <Link onFollow={() => navigate(`/projects/${data.projectID}/sslCerts/${data.projectResourceID}`)}>{`${data.details.domainName}.${data.details.hostedZoneName}`}</Link>
      )
    },
    {
      id: 'certType',
      width: 100,
      header: 'Certificate Type',
      cell: (data: ProjectResource) => data.details.certType || ''
    },
    {
      id: 'projectName',
      width: 100,
      header: 'AWS Project Name',
      cell: (data: ProjectResource) => (
        <Link onFollow={() => navigate(`/projects/${data.projectID}`)}>{data.details.projectName}</Link>
      )
    },
    {
      id: 'requestor',
      width: 100,
      header: 'Requested By',
      cell: (data: ProjectResource) => data.requestorID
    },
    {
      id: 'createdAt',
      width: 100,
      header: 'Created At',
      cell: (data: ProjectResource) => {
        if (data.creationTime) {
          const date: Date = new Date(data.creationTime)
          const year = date.getFullYear()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')

          return `${day}-${month}-${year}`
        }
        return ''
      }
    },
    {
      id: 'approverID',
      width: 100,
      header: 'Approved By (AWS)',
      cell: (data: ProjectResource) => data.approval?.approverID || '-'
    },
    {
      id: 'isdpApproverID',
      width: 100,
      header: 'Approved By (ISDP)',
      cell: (data: ProjectResource) => data.approvalStatus[0].approverID || '-'
    },
    {
      id: 'status',
      width: 100,
      header: 'Status',
      cell: (data: ProjectResource) => {
        const status = data.status
        switch (status) {
          case 'PENDING_APPROVAL':
            return (
              <StatusIndicator type="in-progress" colorOverride="blue">Pending Approval</StatusIndicator>
            )
          case 'APPROVED':
            return <StatusIndicator type="success">Approved</StatusIndicator>
          case 'REJECTED':
            return <StatusIndicator type="error">Rejected</StatusIndicator>
          case 'ERROR':
            return <StatusIndicator type="stopped">Error</StatusIndicator>
        
          default:
            return null
        }
      }
    }
  ]

  return (
    <Table
      header="SSL Certificates"
      columnDefinitions={columnDefinitions}
      items={sslCerts || []}
      disableRowSelect
      loading={isLoading} />
  )
}

export default SslCertificatesOverview