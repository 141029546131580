import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui/components'
import {
	Button,
	Icon,
	Link,
	Popover,
	StatusIndicator,
	Alert
} from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import useMicroservices from '../../hooks/query/useMicroservices'
import type { Microservice } from '../../types/microservices'
import CONSTANTS from '../../constants'

const MicroserviceOnboarding: FC = () => {
	const navigate = useNavigate()

	const { data: microservices, isLoading } = useMicroservices()

	// if (!platforms || isLoading) return <LoadingSpinner />
	const columnDefinitions = [
		{
			id: 'microserviceID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Link
					onFollow={() => {
						navigate(data.microserviceID)
					}}
				>
					{data.microserviceID}
				</Link>
			),
			sortingField: 'microserviceID'
		},
		{
			id: 'microserviceName',
			width: 100,
			header: 'Microservice Name',
			cell: (data: Microservice) => data.microserviceName || '',
			sortingField: 'microserviceName'
		},
		{
			id: 'requestedById',
			width: 100,
			header: 'Requested by',
			cell: (data: Microservice) => data.requestorID || '',
			sortingField: 'requestedById'
		},
		{
			id: 'creationTime',
			width: 100,
			header: 'Creation Time',
			cell: (data: Microservice) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime)
					const year = date.getFullYear()
					const month = (date.getMonth() + 1).toString().padStart(2, '0')
					const day = date.getDate().toString().padStart(2, '0')
					const hours = date.getHours().toString().padStart(2, '0')
					const minutes = date.getMinutes().toString().padStart(2, '0')
					const seconds = date.getSeconds().toString().padStart(2, '0')

					return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
				}
				return ''
			},
			sortingField: 'creationTime'
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Microservice) => {
				const status = data.status // if (!applications || isLoading) return <LoadingSpinner />
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			},
			sortingField: 'status'
		}
	]

	const tableActions = (
		<div
			style={{
				display: 'flex',
				width: 200,
				marginRight: 5
			}}
		>
			<Button variant="primary" onClick={() => navigate('/microservices/new')}>
				New Microservice
			</Button>
			<div
				style={{
					marginLeft: 7,
					marginTop: 4
				}}
			>
				<Popover
					fixedWidth
					position="right"
					triggerType="custom"
					content={
						<p>
							Click the following link for a step-by-step guide on microservice
							onboarding:
							<a
								href={'https://globetelecom.atlassian.net/wiki/spaces/ICCSP/pages/5325685308/SSP+-+Microservice+Onboarding+Step+by+Step+Guide+WIP'}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Plus"
								style={{
									marginLeft: '5px',
									marginTop: '25px'
								}}
							>
								LINK
							</a>
						</p>
					}
				>
					<Icon name="status-info" />
				</Popover>
			</div>
		</div>
	)
	return (
		<>
			<div style={{ marginBottom: '25px' }}>
				<Alert
					statusIconAriaLabel="Warning"
					header="This is a BETA feature"
					type="warning"
				>
					Currently restricted to CXS project team use only. Additional team access in this feature is planned for future releases.
				</Alert>
			</div>
			<Table
				header="Microservices"
				columnDefinitions={columnDefinitions}
				items={microservices || []}
				actions={tableActions}
				disableRowSelect
				sortingDisabled={false}
				loading={isLoading}
			/>
		</>
	)
}

export default MicroserviceOnboarding
