import {
	Alert,
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	FormField,
	Header,
	Icon,
	Popover,
	RadioGroup,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'
import { useEditApiFormStore } from '../../../stores/apis/editApiFormStore'
import useSingleApi from '../../../hooks/query/useSingleApi'
import useEditApi from '../../../hooks/mutate/useEditApi'
import { ApiEditRequest } from '../../../types/apis'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'
import CONSTANTS from '../../../constants'
import useMicroservices from '../../../hooks/query/useMicroservices'
import Ajv from 'ajv'

const apiKeys = [
	'Microservice Name',
	'API Name',
	'API Endpoint',
	'HTTP Method',
	'Status',
	'Description',
	'Request Data Types',
	'Mock Request',
	'Response Data Types',
	'Mock Response'
]

const METHOD_LIST = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'DELETE', value: 'DELETE' }
]

const EditApiForm: FC = () => {
	const ajv = new Ajv();
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	interface envOpt {
		label: string
		value: string
	}

	const { id } = useParams() as { id: string }

	const { data: originalApiRequest, isLoading: isLoadingSingleApi } =
		useSingleApi(id)
	const [microserviceOptions, setMicroserviceOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: microservices } = useMicroservices()

	const navigate = useNavigate()

	console.log('You are on the right page')
	useEffect(() => {
		if (typeof originalApiRequest === 'object' && originalApiRequest !== null) {
			const prefetchedObject = {
				...originalApiRequest.details,
				commentText: ''
			}
			setOriginalRequestFormValues({
				formValues: prefetchedObject,
				isFormValid: true
			})
			reset(prefetchedObject)
		}
	}, [originalApiRequest])

	const {
		formValues,
		actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
	} = useEditApiFormStore()

	const {
		mutate: editApi,
		isLoading: isLoadingEditApi,
		isError: isErrorInEditReqeust
	} = useEditApi(id)

	const {
		control,
		reset,
		trigger,
		formState: { isValid, dirtyFields }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { ...formValues.details, commentText: '' }
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const getOriginalObjVersion = () => {
		let deltaObject: any = {}
		const updatedFields: string[] = Object.keys(dirtyFields)
		for (let fieldName of updatedFields) {
			if (fieldName !== 'commentText')
				deltaObject[fieldName] =
					originalApiRequest?.details[fieldName as keyof object]
		}
		if (Object.keys(deltaObject).length !== 0) {
			deltaObject['lastUpdatedTime'] = originalApiRequest?.lastUpdatedTime
		}
		return deltaObject
	}

	useEffect(() => {
		if (microservices && microservices.length > 0) {
			const filteredMicroservices = microservices.filter(
				(microservice) => microservice.status === 'APPROVED'
			)
			const uniqueMicroservices: envOpt[] = []
			const microserviceNames = new Set()

			filteredMicroservices.forEach((microservice) => {
				if (!microserviceNames.has(microservice.microserviceName)) {
					microserviceNames.add(microservice.microserviceName)
					uniqueMicroservices.push({
						label: microservice.microserviceName,
						value: microservice.microserviceID
					})
				}
			})

			const extractedOptions = uniqueMicroservices
			setMicroserviceOptions(extractedOptions)
		}
	}, [microservices])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let formFieldValues = JSON.parse(JSON.stringify(formValues))
		let apiEditRequest: ApiEditRequest = {
			details: formFieldValues,
			apiID: originalApiRequest?.apiID || '',
			requestorID: originalApiRequest?.requestorID || '',
			creationTime: originalApiRequest?.creationTime || '',
			action: 'UPDATE',
			version: getOriginalObjVersion(),
			commenterID: thisUserEmail,
			comment: formValues.commentText,
			status: 'PENDING_APPROVAL'
		}
		delete apiEditRequest.details.commentText

		editApi(apiEditRequest, {
			onSuccess: () => navigate('/apis'),
			onError: (e) => {}
		})
	}

	if (!originalApiRequest || isLoadingSingleApi) return <LoadingSpinner />

	const processSchemaProperties = (schema: any) => {
		const properties: any = {};
		const required: string[] = Object.keys(schema);
  
		Object.entries(schema).forEach(([key, type]) => {
		  // Basic types: string, number, etc.
		  if (typeof type === 'string') {
			properties[key] = { 
			  type: type.toLowerCase() === 'integer' ? 'integer' : type.toLowerCase() 
			};
		  }
		  // Array type
		  else if (Array.isArray(type)) {
			if (type.length === 1) {
			  // Array of objects
			  if (typeof type[0] === 'object' && type[0] !== null) {
				const { properties: nestedProps, required: nestedRequired } = processSchemaProperties(type[0]);
				properties[key] = {
				  type: 'array',
				  items: {
					type: 'object',
					properties: nestedProps,
					required: nestedRequired,
					additionalProperties: false
				  }
				};
			  } 
			  // Array of basic types
			  else if (typeof type[0] === 'string') {
				properties[key] = {
				  type: 'array',
				  items: { 
					type: type[0].toLowerCase() === 'integer' ? 'integer' : type[0].toLowerCase() 
				  }
				};
			  }
			} else {
			  throw new Error(`Invalid array definition for key "${key}"`);
			}
		  }
		  // Object type: nested schema
		  else if (typeof type === 'object' && type !== null) {
			const { properties: nestedProps, required: nestedRequired } = processSchemaProperties(type);
			properties[key] = {
			  type: 'object',
			  properties: nestedProps,
			  required: nestedRequired,
			  additionalProperties: false
			};
		  } else {
			throw new Error(`Invalid type definition for key "${key}": ${type}`);
		  }
		});
  
		return { properties, required };
	  };

	const validateSchema = (value: string, mockData: string, isMockData: boolean) => {
		try {
		  const schemaJson = JSON.parse(value);
		  const mockJson = JSON.parse(mockData);
	  
		  // Build a valid JSON schema
		  const { properties, required } = processSchemaProperties(schemaJson);
		  const jsonSchema = {
			type: 'object',
			properties,
			required,
			additionalProperties: false,
		  };
	  
		  const validate = ajv.compile(jsonSchema);
		  const isValid = validate(mockJson);
	  
		  if (!isValid) {			
			const errorMessages = validate.errors?.map((error:any) => {
			  if (isMockData && error.keyword === 'type') {
				return `${error.instancePath.slice(1)} should be ${error.params.type}`;
			  }
			});
			return `${errorMessages?.join(', ')}`;
		  }	  
		  
		  return true;

		} catch (error: any) {
		  return `Error: Please check your schema and mock data`;
		}
	  };

	return (
		<>
			<Form
				actions={
					<SpaceBetween direction="horizontal" size="l">
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoadingEditApi}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</SpaceBetween>
				}
				header={
					<Header variant="h1" description="">
						<SpaceBetween direction="horizontal" size="l">
							<Button
								variant="icon"
								iconName="arrow-left"
								onClick={() => navigate(-1)}
								key="icon"
							/>
							<div key="text">{originalApiRequest?.details.ApiName}</div>
						</SpaceBetween>
					</Header>
				}
			>
				<SpaceBetween direction="vertical" size="l">
					<Container>
						<SpaceBetween direction="vertical" size="l">
							<ColumnLayout columns={2} variant="default">
								<div
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<div style={{ width: '650px' }}>
										<FormSelect
											label="Microservice"
											name="microservice"
											control={control}
											rules={{
												required: 'Please Select Option'
											}}
											setFormValues={setFormValues}
											options={microserviceOptions}
											placeholder="Select Microservice Name"
										/>
									</div>
									<div style={{ marginLeft: '10px', marginTop: '25px' }}>
										<Popover
											fixedWidth
											header="Platform GCP Project ID tip"
											position="right"
											triggerType="custom"
											content={
												<p>
													Please see guide on how to enter your GCP Project ID:
													<a
														href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
														target="_blank"
														rel="noopener noreferrer"
														aria-label="Plus"
														style={{
															marginLeft: '10px',
															marginTop: '25px'
														}}
													>
														LINK
													</a>
												</p>
											}
										>
											<Icon name="status-info" />
										</Popover>
									</div>
								</div>
								<div></div>
								<FormInput
									name="name"
									label="Name"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Display Name of API"
									description="Max 20 characters"
									rules={{
										required: 'Please enter API name',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'API name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
								<FormInput
									name="path"
									label="Path"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Endpoint Path"
									description="The endpoint URL/path"
									rules={{
										required: 'Please enter system alias',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
								<FormSelect
									label="HTTP Method"
									name="httpMethod"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={METHOD_LIST}
									placeholder="Select HTTP Method"
								/>
								<FormInput
									name="status"
									label="Status"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Design"
									disabled
									rules={{
										required: 'Please enter system alias',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
							</ColumnLayout>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label style={{ fontWeight: '600', marginBottom: '5px' }}>
								Description
							</label>
							<Textarea
								onChange={({ detail }) => setFormValues('description',detail.value)}
								name='description'
								value={formValues.description}
								placeholder="Enter description of Endpoint"
							/>
						</div>
						<ColumnLayout columns={2} variant="default">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Request Data Types"
									name="requestDataTypes"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.mockRequest) return true;
												return validateSchema(value, formValues.mockRequest, false);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Request"
									name="mockRequest"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter mock request data"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.requestDataTypes) return true;
												return validateSchema(formValues.requestDataTypes, value, true);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Response Data Types"
									name="responseDataTypes"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.mockResponse) return true;
												return validateSchema(value, formValues.mockResponse, false);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Response"
									name="mockResponse"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.responseDataTypes) return true;
												return validateSchema(formValues.responseDataTypes, value, true);
											}
										}
									}}
								/>
							</div>
						</ColumnLayout>

							<FormRequiredTags
								name="apiDetails"
								label="Api Details"
								control={control}
								tagKeys={apiKeys}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the required value`,
									pattern: {
										value: /^(?!\s)(.*\S)?(?<!\s)$/,
										message: 'Leading and trailing whitespaces are not allowed.'
									}
								}}
							/>
							<FormField label="" description="">
								<ColumnLayout columns={3} variant="default">
									<FormInput
										name="budget"
										label="Platform Budget Allocated"
										description="In US Dollar (USD) currency"
										type="text"
										rules={{
											required: 'Please enter Platform Budget allocated',
											pattern: {
												value: /^\d+$/,
												message:
													'Please enter valid Platform Budget allocated without spaces'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder="0"
									/>
									<FormDatePicker
										name="crfsDate"
										label="CRFS Date"
										description="Customer Ready for Service Date"
										rules={{
											required: 'Please Enter CRFS Date'
										}}
										control={control}
										setFormValues={setFormValues}
									/>
								</ColumnLayout>
							</FormField>
						</SpaceBetween>
					</Container>
					<Container
						header={<Header variant="h3">Comment History</Header>}
						footer={
							<SpaceBetween direction="vertical" size="l">
								<FormInputArea
									name="commentText"
									label=""
									description="Add comments here"
									control={control}
									rules={{
										required: 'Please enter your comments here',
										minLength: {
											value: 2,
											message: 'Minimum comment length should be 2'
										},
										maxLength: {
											value: 50,
											message: 'Maximum comment length should be 50'
										}
									}}
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						}
					></Container>
					{isErrorInEditReqeust && (
						<Container>
							<Box variant="p">
								<Alert
									statusIconAriaLabel="Error"
									type="error"
									header="Error Submitting Request, Please try again."
								/>
							</Box>
						</Container>
					)}
				</SpaceBetween>
			</Form>
		</>
	)
}

export default EditApiForm
