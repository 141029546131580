import { FC, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
	Button,
	Container,
	Form,
	SpaceBetween,
	Alert
} from '@cloudscape-design/components'
import type { ApiApprovalRequest } from '../../../types/apis'
import useAcceptApi from '../../../hooks/mutate/useAcceptApi'
import { useNavigate } from 'react-router-dom'

interface ApprovalFormProps {
	thisApiID: string
	shouldShowApprovalForm: (shouldShow: boolean) => void
}

const ApprovalForm: FC<ApprovalFormProps> = ({
	thisApiID,
	shouldShowApprovalForm
}) => {
	const { authState } = useOktaAuth()
	const navigate = useNavigate()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { mutate: acceptApi, isLoading: isAccepting } = useAcceptApi(thisApiID)

	const handleApprove = () => {
		const approvalRequest: ApiApprovalRequest = {
			action: "APPROVE",
			apiID: thisApiID,
			approverID: thisUserEmail,
			details: {}
		}
		acceptApi(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				// clearFormValues()
				window.location.reload()
				navigate(`/apis/${thisApiID}`)
			}
		})
	}

	// Commenting to update here
	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
						Cancel
					</Button>
					
					<Button
						variant="primary"
						loading={isAccepting}
						onClick={handleApprove}
					>
						Approve
					</Button>
				</SpaceBetween>
			}
		>
			<Container>
			<Alert
      			statusIconAriaLabel="Info"
      			header="Are you sure you want to approve this request?"
    			>
      			Review the request details.
    		</Alert>
			</Container>
			{/* <Container>
				<SpaceBetween direction="vertical" size="l">
					<ColumnLayout columns={2} variant="default">
						<FormInput
							name="namePrefix"
							label="Cluster Name Prefix"
							control={control}
							setFormValues={setFormValues}
							rules={{
								required: 'Cluster Name Prefix is required',
								pattern: {
									value: /^[a-z0-9-_]+(?:\s*[a-z0-9-_]+)*$/,
									message: 'Uppercase letters, whitespaces are not allowed.'
								}
							}}
							placeholder="Enter Prefix for your Cluster Name"
							disabled={true}
						/>

						<FormInput
							name="networkProjectID"
							label="Network Project ID"
							control={control}
							setFormValues={setFormValues}
							rules={{
								required: 'Network Project ID is required'
							}}
							disabled={true}
							placeholder="Enter Network Project ID"
						/>
						<FormInput
							name="network"
							label="VPC Name"
							control={control}
							setFormValues={setFormValues}
							rules={{
								required: 'VPC Name is required'
							}}
							disabled={true}
							placeholder="Enter VPC Name"
						/>
						<FormInput
							name="subnetwork"
							label="Subnet Name"
							control={control}
							setFormValues={setFormValues}
							rules={{
								required: 'Subnet Name is required',
								pattern: {
									value: /^[a-z0-9-_]+(?:\s*[a-z0-9-_]+)*$/,
									message: 'Uppercase letters, whitespaces are not allowed.'
								}
							}}
							placeholder="Enter Subnet Name"
						/>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap'
							}}
						>
							<div style={{ flex: 1, minWidth: '250px' }}>
								<FormInput
									name="ipRangePods"
									label="Name of Secondary range for Pods"
									control={control}
									setFormValues={setFormValues}
									rules={{
										required: 'Secondary Range Name for Pods is required'
									}}
									placeholder="Enter Name of Secondary range for Pods"
								/>
							</div>
							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Name of Secondary range for Pods tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to fill the Secondary range for
											Pods:
											<a
												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '5px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap'
							}}
						>
							<div style={{ flex: 1, minWidth: '250px' }}>
								<FormInput
									name="ipRangeServices"
									label="Name of Secondary range for Services"
									control={control}
									setFormValues={setFormValues}
									rules={{
										required: 'Secondary Range Name for Services is required'
									}}
									placeholder="Enter Name of Secondary range for Services"
								/>
							</div>
							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Name of Secondary range for Services tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to fill the Secondary range for
											Services:
											<a
												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '5px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap'
							}}
						>
							<div style={{ flex: 1, minWidth: '250px' }}>
								<FormInput
									name="subnetworkRange"
									label="Node Subnet Range"
									control={control}
									setFormValues={setFormValues}
									rules={{
										required: 'Node Subnet Range is required',
										pattern: {
											value: /^[0-9./]*$/,
											message: 'Only numbers, / and . is allowed'
										}
									}}
									placeholder="Enter Node Subnet Range"
								/>
							</div>
							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Node Subnet Range tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to fill the Node Subnet Range:
											<a
												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '5px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap'
							}}
						>
							<div style={{ flex: 1, minWidth: '250px' }}>
								<FormInput
									name="controlPlaneRange"
									label="Control Plane Range"
									control={control}
									setFormValues={setFormValues}
									rules={{
										required: 'Control Panel Range is required',
										pattern: {
											value: /^[0-9./]*$/,
											message: 'Only numbers, / and . is allowed'
										}
									}}
									placeholder="Enter Control Plane Range"
								/>
							</div>
							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Control Plane Range tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to fill the Control Plane Range:
											<a
												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '5px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<FormInput
							name="ccmClusterName"
							label="CCM Cluster Name"
							type="text"
							control={control}
							setFormValues={setFormValues}
							placeholder=""
							disabled={true}
						/>

						<FormInput
							name="ccmProjectId"
							label="CCM Project ID"
							type="text"
							control={control}
							setFormValues={setFormValues}
							placeholder=""
							disabled={true}
						/>

						<FormInput
							name="region"
							label="Region"
							type="text"
							control={control}
							setFormValues={setFormValues}
							description="Region of your GKE Cluster (by default Singapore)"
							disabled={true}
						/>

						<FormInput
							label="Argocd Namespace"
							name="argocdNamespace"
							control={control}
							rules={{
								required: 'Argocd Namespace is required'
							}}
							setFormValues={setFormValues}
							disabled={true}
						/>
					</ColumnLayout>
					<ExpandableSection headerText="Optional Input(s)">
						<SpaceBetween direction="vertical" size="l">
							<FormInput
								label="Release Channel"
								name="releaseChannel"
								control={control}
								rules={{
									required: 'Release Channel is required'
								}}
								setFormValues={setFormValues}
								disabled={true}
							/>
							<FormInput
								name="kVersion"
								label="Kubernetes Version"
								control={control}
								description="Optional | Enter the latest version of Kubernetes"
								setFormValues={setFormValues}
								placeholder="Enter Kubernetes Version"
							/>
						</SpaceBetween>
					</ExpandableSection>
				</SpaceBetween>
			</Container> */}
		</Form>
	)
}

export default ApprovalForm
