import {
	Box,
	Container,
	Header,
	ColumnLayout
} from '@cloudscape-design/components'
import { FC } from 'react'

interface ValueWithLabelProps {
	label: string
	value: string
	version: string[]
}

interface ApiProps {
	Api: Record<any, any>
}

const ValueWithLabel: FC<ValueWithLabelProps> = ({ label, value, version }) => {
	// Function to check if a string is JSON and format it
	const formatIfJson = (value: string) => {
	  try {
		const parsed = JSON.parse(value);
		return JSON.stringify(parsed, null, 2);
	  } catch {
		return value;
	  }
	};
  
	// Function to render the value with proper formatting
	const renderValue = (value: string) => {
	  if (label === "Mock Request" || label === "Mock Response") {
		return (
		  <pre style={{ 
			margin: 0,
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
			fontFamily: 'monospace',
			fontSize: '14px',
		  }}>
			{formatIfJson(value)}
		  </pre>
		);
	  }
	  return <div>{value}</div>;
	};
  
	return (
	  <div>
		<Box variant="awsui-key-label">{label}</Box>
		{renderValue(value)}
		{version.map((item, index) => (
		  <div key={index}>
			<s>{item}</s>
		  </div>
		))}
	  </div>
	);
  };

const RenderApiFields: FC<ApiProps> = ({ Api }) => {
	return (
		<>
			<Container>
				<Header variant="h3">Request Details</Header>
				<ColumnLayout columns={3} variant="text-grid">
					{Api.map((element: ValueWithLabelProps, index: number) => {
						return <ValueWithLabel {...element} key={element.label + index} />
					})}
				</ColumnLayout>
			</Container>
		</>
	)
}

export default RenderApiFields
