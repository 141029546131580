import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { ProjectResource } from "../../types/projectResource";

const useProjectResources = (projectId: string) => {
  return useQuery({
    queryKey: ['projectResources', projectId],
    queryFn: () => api.projects.resources.getProjectResources(projectId),
    select(data) {
      return data.body as Array<ProjectResource>
    }
  })
}

export default useProjectResources