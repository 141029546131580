import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Badge,
	Button,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

const getStatusBadge = (status: string | undefined) => {
	switch (status) {
		case 'PENDING_APPROVAL':
			return (
				<SpaceBetween direction="horizontal" size="s">
					<Badge color="blue" key={0}>
						{status}
					</Badge>

					<Badge color="grey" key={1}>
						APPROVED
					</Badge>

					<Badge color="grey" key={2}>
						REJECTED
					</Badge>
				</SpaceBetween>
			)
		case 'APPROVED':
			return (
				<SpaceBetween direction="horizontal" size="s">
					<Badge color="grey" key={0}>
						PENDING_APPROVAL
					</Badge>

					<Badge color="green" key={1}>
						{status}
					</Badge>

					<Badge color="grey" key={2}>
						REJECTED
					</Badge>
				</SpaceBetween>
			)
		case 'REJECTED':
			return (
				<SpaceBetween direction="horizontal" size="s">
					<Badge color="grey" key={0}>
						PENDING_APPROVAL
					</Badge>

					<Badge color="grey" key={1}>
						APPROVED
					</Badge>

					<Badge color="red" key={2}>
						{status}
					</Badge>
				</SpaceBetween>
			)
		default:
			return null
	}
}

interface HeaderWithStatusBadgesProps {
	headerName: string
	status: string
	navigateTo?: string
}

const HeaderWithStatusBadges: FC<HeaderWithStatusBadgesProps> = ({
	headerName,
	status,
	navigateTo
}) => {
	const navigate = useNavigate()

	return (
		<Header>
			<SpaceBetween direction="horizontal" size="l">
				<Button
					variant="icon"
					iconName="arrow-left"
					onClick={() => navigateTo ? navigate(navigateTo) : navigate(-1)}
					key="icon"
				/>
				<div key="text">{headerName}</div>
				{getStatusBadge(status)}
			</SpaceBetween>
		</Header>
	)
}

export default HeaderWithStatusBadges
