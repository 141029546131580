import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import type { ProjectResource } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const getAllProjectResources = async(): Promise<{statusCode: number, body: Array<ProjectResource>}> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}`)
  if (!response.ok) {
    throw new Error(`Error Response ${response.status}`)
  }
  return response.json()
}

export default getAllProjectResources